export enum TAB_ID {
  INVALID = -999,
  APP = -1, // 特別なフラグ (アプリで保存した)
  STAFFS = 0, // 勤務者
  VISITORS = 1, // 利用者数
  EXCLUSIVE_USE = 2, // 一時占用
  INQUIRY = 3, // 問合せ苦情要望
  LOST_AND_FOUND = 4, // 遺失・拾得物
  INCIDENTS = 5, // 事故・通報
  FACILITY_USE = 6, // 施設利用状況
  PRIVATE_BIZ = 7, // 自主事業
  PUBLIC_BIZ = 8, // 住民協働事業
  SPECIAL_NOTE = 9, // 特記メモ
  PAY_FACILITY = 10, // 有料施設
  WORK_REPORT = 11, // 巡回作業
  COMMENT = 12, // 所長コメント
  EXCEL_IMPORT = 13, // エクセル取り込み
  WORK_HISTORY = 14, // 作業履歴
  SALES_INPUT = 15, // 売上登録
  PERIODICAL_EVENT = 16, // 期間イベント
}

export const DailyMenuOrder: TAB_ID[] = [
  TAB_ID.APP,
  TAB_ID.STAFFS,
  TAB_ID.VISITORS,
  TAB_ID.INQUIRY,
  TAB_ID.EXCLUSIVE_USE,
  TAB_ID.INCIDENTS,
  TAB_ID.PAY_FACILITY,
  TAB_ID.PRIVATE_BIZ,
  TAB_ID.PUBLIC_BIZ,
  TAB_ID.SPECIAL_NOTE,
  TAB_ID.COMMENT,
];

export const WorkMenuOrder: TAB_ID[] = [
  TAB_ID.APP,
  TAB_ID.STAFFS,
  TAB_ID.WORK_REPORT,
  TAB_ID.SPECIAL_NOTE,
  TAB_ID.COMMENT,
];

export enum REPORT_TYPE {
  DAILY = 0, // 日報
  WORK = 1, // 作業日報
  OTHERS = 2, // その他
}

export enum DAY_TYPE {
  NORMAL = 0,
  HOLIDAY = 1,
}

// 円入力時の上げ幅
export const STEP_YEN = 100;

// 分を入力する時の上げ幅
export const MINUTE_STEP = 5;

// ファイル選択
export const FILE_ACCEPTS_ALL = "*";
export const FILE_ACCEPTS_IMAGES = "image/*";

// NameIDのデータ未設定状態
export const INVALID_ID = -1;
export const INVALID_ID_STR = "-1";
