import { memo, useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row, Stack } from "react-bootstrap";
import styled from "styled-components";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { NameID } from "common/types/NameID";
import MySwitch from "common/components/atoms/MySwitch";

import { InquiryData } from "../types/InquiryInfo";
import { InquiryMeta } from "../types/InquiryMeta";
import { CancelButton } from "common/components/atoms/CancelButton";

export type TargetInquiry = {
  index: number; // 0以下なら新規
  data: InquiryData;
};

type Props = {
  target: TargetInquiry | null;
  metaData: InquiryMeta;
  onOK: (data: InquiryData) => void;
  onCancel: () => void;
};

const CLEAR: string = "clear";

//=====================================
// 問合せ苦情要望編集ダイアログ
//=====================================
export const EditInquiryDlg = memo(({ target, metaData, onOK, onCancel }: Props) => {
  const [item, setItem] = useState<InquiryData | null>(null);
  const [currentDetails, setCurrentDetails] = useState<NameID[] | undefined>();

  useEffect(() => {
    setItem(target?.data ?? null);
    if (target) {
      const cat = metaData.categories.find((x) => x.category.id === target.data.category?.id);
      setCurrentDetails(cat?.subCategories);
    }
  }, [target, metaData]);

  return (
    <Modal show={target !== null} size="lg">
      <Modal.Header>
        {target && target.index < 0 ? "問合せ苦情要望の追加" : "問合せ苦情要望の編集"}
      </Modal.Header>
      <Modal.Body>
        {item && (
          <Stack className="gap-3">
            <FloatingLabel controlId="floatingInput" label="整理番号" hidden={true}>
              <Form.Control
                type="text"
                placeholder="ex. p_0001"
                value={item.inquiryNo}
                onChange={(e) => {
                  setItem({ ...item!, inquiryNo: e.target.value });
                }}
              />
            </FloatingLabel>

            <Form.Group as={Row}>
              <Form.Label column md={3}>
                要求種別 *
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="要求種別"
                  value={`${item.category?.id}`}
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.currentTarget.value;
                    // 選択解除
                    if (val === CLEAR) {
                      setItem({ ...item, category: undefined });
                      return;
                    }

                    // 選択
                    const cat = metaData.categories.find((x) => x.category.id.toString() === val);
                    setItem({ ...item, category: cat!.category!, subCategory: undefined });
                    setCurrentDetails(cat?.subCategories);
                  }}
                >
                  <option value={CLEAR}>要求種別を選択</option>
                  {metaData.categories.map((cat) => {
                    return (
                      <option key={`cat_${cat.category.id}`} value={cat.category.id}>
                        {cat.category.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={3}>
                要求詳細
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  disabled={currentDetails === undefined || currentDetails.length === 0}
                  aria-label="要求詳細"
                  value={`${item.subCategory?.id}`}
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.currentTarget.value;
                    // 選択解除
                    if (val === CLEAR) {
                      setItem({ ...item, subCategory: undefined });
                      return;
                    }
                    const cat = metaData.categories.find(
                      (x) => x.category.id === item.category?.id
                    );
                    const subCat = cat!.subCategories?.find((x) => x.id.toString() === val);
                    setItem({ ...item, subCategory: subCat });
                  }}
                >
                  {currentDetails && currentDetails.length > 0 ? (
                    <>
                      <option value={CLEAR}>詳細種別を選択</option>
                      {currentDetails.map((sub) => (
                        <option key={`sub_${sub.id}`} value={`${sub.id}`}>
                          {sub.name}
                        </option>
                      ))}
                      ;
                    </>
                  ) : (
                    <option value={CLEAR}>詳細はありません</option>
                  )}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={3}>
                受付種別 *
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="受付者"
                  value={item.method?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const method = metaData.methods.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!method) {
                      setItem({ ...item, method: undefined });
                      return;
                    }

                    setItem({ ...item, method: method! });
                  }}
                >
                  <option value={CLEAR}>受付種別を選択</option>
                  {metaData.methods.map((method) => (
                    <option key={`emp_${method.id}`} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={3}>
                受付者
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="受付者"
                  value={item.recipient?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const recipient = metaData.employees.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!recipient) {
                      setItem({ ...item, recipient: undefined });
                      return;
                    }
                    setItem({ ...item, recipient: recipient! });
                  }}
                >
                  <option value={CLEAR}>受付者を選択</option>
                  {metaData.employees.map((employee) => (
                    <option key={`emp_${employee.id}`} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <FloatingLabel controlId="floatingInput" label="内容">
              <Form.Control
                as="textarea"
                value={item?.contents}
                onChange={(e) => {
                  setItem({ ...item, contents: e.target.value });
                }}
                style={{ height: "100px" }}
              />
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="対応内容">
              <Form.Control
                as="textarea"
                value={item?.action}
                onChange={(e) => {
                  setItem({ ...item, action: e.target.value });
                }}
                style={{ height: "100px" }}
              />
            </FloatingLabel>

            <Form.Group as={Row} className="d-flex align-items-center">
              <SFormLabel column sm={1}>
                件数
              </SFormLabel>
              <Col sm={3}>
                <MyNumberInput
                  initialValue={item.numIncident}
                  changed={(value) => {
                    setItem({ ...item, numIncident: value });
                  }}
                />
              </Col>

              <SFormLabel column sm={1}>
                完了
              </SFormLabel>
              <Col sm={1}>
                <MySwitch
                  initialValue={item.completed}
                  changed={(changed) => {
                    setItem({ ...item, completed: changed });
                  }}
                />
              </Col>

              <SFormLabel column sm={1}>
                報告
              </SFormLabel>
              <Col sm={1}>
                <MySwitch
                  initialValue={item.reported}
                  changed={(changed) => {
                    setItem({ ...item, reported: changed });
                  }}
                />
              </Col>
            </Form.Group>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        {item && (
          <PrimaryButton
            className="mx-2"
            disabled={
              !item.category ||
              !item.method ||
              (currentDetails && currentDetails.length > 0 && !item.subCategory)
            }
            onClick={() => {
              onOK(item!);
            }}
            title="決定"
          />
        )}
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
});

const SFormLabel = styled(Form.Label)`
  text-align: left;
`;
