export const theme = {
  color: {
    title: "hsl(205, 68%, 39%)",
    menuHeader: "hsl(200, 43%, 57%)",
    menuHeaderText: "hsl(200, 43%, 100%)",
    menuSelectedBg: "#d6f5dd88",
    menuSelectedFg: "#226622",
    primaryButton: "hsl(216,60%,52%)",
    primaryButtonHover: "hsl(216,80%,80%)",
    functionButton: "hsl(104, 43%, 52%)",
    functionButtonBorder: "hsl(104, 63%, 32%)",
    functionButtonDisabled: "#8a8d8a;",
    functionButtonHover: "hsl(104, 100%, 32%)",
    editButton: "hsl(216,60%,52%)",
    editButtonBorder: "#444",
    editButtonHover: "hsl(216,80%,80%)",
    deleteButton: "hsl(45, 80%, 53%)",
    deleteButtonBorder: "hsl(45, 80%, 33%)",
    deleteButtonHover: "hsl(45, 100%, 53%)",
    disabled: "hsl(205, 10%, 39%)",
  },
};
