import { memo } from "react";
import { Modal } from "react-bootstrap";

import PrimaryButton from "../atoms/PrimaryButton";
import { CancelButton } from "../atoms/CancelButton";

type Props = {
  show: boolean;
  title: string;
  messages: string[] | undefined;
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
};

const ConfirmationDlg = memo((props: Props) => {
  const { show, title, messages, onOk, onCancel, okText, cancelText } = props;
  return (
    <Modal show={show} variant="info">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        {messages &&
          messages.map((msg, index) => (
            <div key={`msg_${index}`}>
              <span>{msg}</span>
              <br />
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton className="mx-2" onClick={onOk} title={okText || "OK"} />
        <CancelButton onClick={onCancel} text={cancelText} />
      </Modal.Footer>
    </Modal>
  );
});

export default ConfirmationDlg;
