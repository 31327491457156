import { useRef, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";

import PrimaryButton from "../../atoms/PrimaryButton";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  show: boolean;
  title: string;
  accepts: string;
  hasComment?: boolean;
  onCancel: () => void;
  onOk: (title: string, comment: string, file: File) => void;
};

export const AddFileDlg = (props: Props) => {
  const { show, title, accepts, hasComment = false, onCancel, onOk } = props;

  const [fileTitle, setFileTitle] = useState("");
  const [comment, setComment] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);

  const fileInput = useRef<HTMLInputElement | null>(null);

  const uploadFileHandler = () => {
    if (!file) return;
    onOk(fileTitle, comment, file);
    setFileTitle("");
  };

  //--------------------------------------
  // ファイルの選択処理
  //--------------------------------------
  const fileSelectedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{`${title}追加`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group>
              <Form.Control
                type="file"
                ref={fileInput}
                accept={accepts}
                onChange={fileSelectedHandler}
              ></Form.Control>
            </Form.Group>
            <FloatingLabel className="mb-2 my-2 " label="タイトル">
              <Form.Control
                type="text"
                placeholder="タイトルを入力してください"
                value={fileTitle}
                onChange={(e) => setFileTitle(e.target.value)}
              />
            </FloatingLabel>
            {hasComment && (
              <FloatingLabel className="mb-2 my-2 " label="コメント">
                <Form.Control
                  as="textarea"
                  placeholder="コメントを入力してください"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          className="mx-2"
          disabled={!file || !fileTitle}
          onClick={uploadFileHandler}
          title="決定"
        />
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
