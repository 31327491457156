import { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Stack, Form, Row, Col } from "react-bootstrap";

import { Task } from "../types/WorkReportData";
import { WorkReportMeta } from "../types/WorkReportMeta";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { INVALID_ID, INVALID_ID_STR } from "common/types/consts/Defines";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { NameIDList } from "common/components/molecules/NameIDList";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  index: number;
  target: Task | null;
  metaData: WorkReportMeta;
  onOK: (data: Task) => void;
  onCancel: () => void;
};

export const EditTaskDlg = (props: Props) => {
  const { index, target, metaData } = props;
  const [item, setItem] = useState<Task | null>(props.target);
  const [step, setStep] = useState(1.0);

  useEffect(() => {
    setItem(target);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={item !== null} size="lg">
      <Modal.Header>{index === INVALID_ID ? "作業の追加" : "作業の修正"}</Modal.Header>
      <Modal.Body>
        {item && (
          <Stack className="gap-3">
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                作業種別*
              </SFormLabel>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="作業種別"
                  value={`${item.taskType.id}`}
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.currentTarget.value;
                    if (val === INVALID_ID_STR) {
                      setItem({ ...item, taskType: { name: "", id: INVALID_ID }, unitOfTask: "" });
                      return;
                    }

                    const taskType = metaData.workDefinitions.find((x) => x.id.toString() === val);
                    setItem({
                      ...item,
                      taskType: { id: taskType!.id, name: taskType!.name },
                      unitOfTask: taskType?.workUnit || "",
                    });
                  }}
                >
                  <option value={INVALID_ID}>選択なし</option>
                  {metaData.workDefinitions.map((taskType) => {
                    return (
                      <option key={`taskType_${taskType.id}`} value={taskType.id}>
                        {taskType.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>

            <SAmountRow>
              <SFormLabel column md={2}>
                計画数量
              </SFormLabel>
              <MyNumberInput
                style={{ width: "100px", marginLeft: "-10px", marginRight: "-5px" }}
                step={step}
                type="float"
                initialValue={item.plannedAmount}
                changed={(val) => (item.plannedAmount = val)}
              />
              <SUnitLabel style={{ width: "auto" }}>{item.unitOfTask}</SUnitLabel>
              <SFormLabel style={{ width: "auto", marginLeft: "15px" }}>作業数量</SFormLabel>
              <MyNumberInput
                style={{ width: "100px", marginRight: "-5px" }}
                step={step}
                type="float"
                initialValue={item.actualAmount}
                changed={(val) => (item.actualAmount = val)}
              />
              <SUnitLabel style={{ width: "auto" }}>{item.unitOfTask}</SUnitLabel>
              <SFormLabel style={{ width: "auto", marginLeft: "15px" }}>増加量</SFormLabel>
              <Form.Select
                value={step}
                onChange={(e) => setStep(+e.currentTarget.value)}
                style={{ width: "auto" }}
                className="py-0"
              >
                <option value={0.1}>0.1</option>
                <option value={1.0}>1</option>
                <option value={10.0}>10</option>
                <option value={100}>100</option>
                <option value={1000}>1000</option>
              </Form.Select>
            </SAmountRow>
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                作業内容
              </SFormLabel>
              <Col md={10}>
                <Form.Control
                  as="textarea"
                  style={{ height: "150px" }}
                  value={item.taskContent}
                  onChange={(e) => {
                    setItem({ ...item, taskContent: e.target.value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                事業者区分
              </SFormLabel>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="事業者区分"
                  value={`${item.contractType.id}`}
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.currentTarget.value;
                    if (val === INVALID_ID_STR) {
                      setItem({ ...item, contractType: { name: "", id: INVALID_ID } });
                      return;
                    }

                    const contractType = metaData.contractTypes.find(
                      (x) => x.id.toString() === val
                    );
                    setItem({
                      ...item,
                      contractType: { id: contractType!.id, name: contractType!.name },
                    });
                  }}
                >
                  <option value={INVALID_ID}>選択なし</option>
                  {metaData.contractTypes.map((contractType) => {
                    return (
                      <option key={`contractType_${contractType.id}`} value={contractType.id}>
                        {contractType.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                事業者名称
              </SFormLabel>
              <Col md={6}>
                <Form.Control
                  type="text"
                  value={item.contractorName}
                  onChange={(e) => {
                    setItem({ ...item, contractorName: e.target.value });
                  }}
                />
              </Col>
            </Form.Group>
            <Col sm={12} md={4} className="mt-3">
              <Form.Label>地区</Form.Label>
              {metaData.areas.length > 0 ? (
                <NameIDList
                  lists={metaData.areas}
                  selecteds={item.areas}
                  stringWhenEmpty="選択なし"
                  maxHeight="200px"
                  selectedChanged={(vals) => (item.areas = vals)}
                />
              ) : (
                <div className="h6">地区の定義がありません</div>
              )}
            </Col>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        {item && (
          <PrimaryButton
            className="mx-2"
            disabled={item.taskType.id === INVALID_ID}
            onClick={() => {
              props.onOK(item!);
            }}
            title="決定"
          />
        )}
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SAmountRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 10px;
`;

const SFormLabel = styled(Form.Label)`
  text-align: left;
`;

const SUnitLabel = styled(Form.Label)`
  margin-left: 0;
`;
