import { createRef, useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { PageTop } from "common/components/organisms/PageTop";
import { BaseForm } from "common/components/templates/BaseForm";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { createDocumentTitle } from "utils/commonTools";
import { useFileUtils } from "common/hooks/useFileUtils";

import { useWorkReport } from "./hooks/useWorkReport";
import { useWorkReportData } from "./hooks/useWorkReportData";
import { PatrolReportPanel } from "./components/PatrolReportPanel";
import { TaskReportPanel } from "./components/TaskReportPanel";
import { TaskFilesPanel } from "./components/TaskFilesPanel";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { WorkReportMeta } from "./types/WorkReportMeta";
import { WorkReports } from "./types/WorkReportData";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

let isInitializing = false;

//=====================================
// 巡回作業日報ページ
//=====================================
export const WorkReportPage = (props: { pageTitle: string }) => {
  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const [activeTab, setActiveTab] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);

  const { loadMetaData, getReportData, saveReportData } = useWorkReport();
  const { updateMeta, createEmptyReport } = useWorkReportData();
  const { execFileUpload, execFileDelete } = useFileUtils();

  const [metaData, setMetaData] = useState<WorkReportMeta | null>(null);
  const [reports, setReports] = useState<WorkReports>();

  const taskRef = createRef<ForceCloseHandler>();
  const pictureListRef = createRef<ForceCloseHandler>();

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<WorkReportMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: WorkReportMeta) => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );

    const updatedMeta = updateMeta(baseData, data);
    setMetaData(updatedMeta);

    if (data != null) {
      const newPeriods = updatedMeta.periods.filter(
        (x) => !data.reports.find((y) => y.period.id === x.id)
      );
      data.reports = [...data.reports, ...newPeriods?.map((x) => createEmptyReport(x))];
      setReports(data);
    } else {
      setReports({ reports: updatedMeta.periods.map((period) => createEmptyReport(period)) });
    }

    if (activeTab === "" && baseData.periods.length > 0)
      setActiveTab(getTabId(baseData.periods[0].id));
  };

  const processPictures = async (pictures: RegisteredFileInfo[]) => {
    await Promise.all(
      pictures.map(async (theFile) => {
        if (theFile.deleted && theFile.fileId >= 0) {
          await execFileDelete(selectedPark!.parkId, theFile);
        } else if (theFile.file) {
          // ファイルの削除
          await execFileUpload(selectedPark!.parkId, theFile);
        }
      })
    );
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    // ダイアログを開いている時に編集権限譲渡リクエストが来た場合
    taskRef.current?.forceClose();
    pictureListRef.current?.forceClose();

    setErrorMessage("");
    setIsLoading(true);

    if (!selectedPark || !reports) return;

    await Promise.all(reports.reports.map((report) => processPictures(report.pictures)));
    reports.reports.forEach(
      (report) => (report.pictures = report.pictures.filter((pic) => !pic.deleted))
    );

    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      currentDate,
      reports,
      props.pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }
      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  useEffect(() => {
    document.title = createDocumentTitle(props.pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  //--------------------------------------
  // タブIDの生成
  //--------------------------------------
  const getTabId = (index?: number) => `work_${index ?? -1}`;

  const reloadData = async () => {
    if (metaData) await loadReportData(metaData);
  };

  return (
    <DashboardTemplate>
      <FormContainer size="max">
        {!selectedPark && <div> 公園が選択されていません </div>}
        <PageTop
          title={props.pageTitle}
          reportType={REPORT_TYPE.WORK}
          tabId={TAB_ID.WORK_REPORT}
          lastUpdated={lastUpdated}
          onEditStart={async () => await reloadData()}
          onSave={!readOnly ? onSave : undefined}
          onCancel={async () => await reloadData()}
        />
        <BaseForm className="p-3">
          <Tabs
            activeKey={activeTab}
            onSelect={(e) => {
              setActiveTab(e!);
            }}
          >
            {metaData &&
              reports &&
              metaData.periods.map((period) => {
                const index = reports.reports.findIndex((x) => x.period.id === period.id);
                const report = index >= 0 ? reports.reports[index] : createEmptyReport(period);
                return (
                  <Tab
                    eventKey={getTabId(period.id)}
                    title={period.name}
                    key={`periodtab_${period.id}`}
                    tabClassName={`${period.isDeleted && "deleted-tab"}`}
                  >
                    <div className="p-2">
                      <PatrolReportPanel
                        meta={metaData}
                        readonly={period.isDeleted || !isEditing}
                        data={report}
                        dataChanged={(newData) => {
                          reports.reports[index] = newData;
                        }}
                      />
                      <hr />
                      <TaskReportPanel
                        tasks={report.tasks}
                        meta={metaData}
                        readonly={period.isDeleted || !isEditing}
                        tasksChanged={(tasks) => (reports.reports[index].tasks = tasks)}
                        ref={activeTab === getTabId(period.id) ? taskRef : undefined}
                      />
                      <hr className="mb-3" />
                      <div className="mx-2">
                        <TaskFilesPanel
                          pictures={report.pictures}
                          readonly={period.isDeleted || !isEditing}
                          picturesChanged={(files) => {
                            report.pictures = files ?? [];
                          }}
                          ref={activeTab === getTabId(period.id) ? pictureListRef : undefined}
                        />
                      </div>
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
        </BaseForm>
      </FormContainer>
    </DashboardTemplate>
  );
};
