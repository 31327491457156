import { useState } from "react";
import styled from "styled-components";
import { Col, Modal, Row } from "react-bootstrap";

import { CancelButton } from "common/components/atoms/CancelButton";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { FileCard } from "common/components/organisms/files/FileCard";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";

type Props = {
  show: boolean;
  candidates: { title: string; pictures: RegisteredFileInfo[] }[];
  selecteds: RegisteredFileInfo[];
  onOK: (value: RegisteredFileInfo[]) => void;
  onCancel: () => void;
};
export const SelectPictureDlg = (props: Props) => {
  const { show, candidates, selecteds } = props;
  const [selectedPictures, setSelectedPictures] = useState<RegisteredFileInfo[]>(selecteds);

  const onClick = (file: RegisteredFileInfo) => {
    if (selectedPictures.find((x) => x.fileId === file.fileId)) {
      setSelectedPictures(selectedPictures.filter((x) => x.fileId !== file.fileId));
    } else setSelectedPictures([...selectedPictures, file]);
  };

  return (
    <Modal show={show}>
      <Modal.Header> 報告写真の選択 </Modal.Header>
      <Modal.Body>
        <span className="notes">※保存済の写真のみ表示されます</span>
        <SlistDiv>
          {candidates.map((detail) =>
            detail.pictures.length === 0 ? (
              <></>
            ) : (
              <>
                <span className="h6 mt-2">{detail.title}</span>
                <Row>
                  {detail.pictures.map((file) => (
                    <Col sm={6} className="my-1">
                      <SClickDiv
                        key={`pick_${file.fileId}`}
                        onClick={() => onClick(file)}
                        className={`${
                          selectedPictures.find((x) => x.fileId === file.fileId) ? "selected" : ""
                        }`}
                      >
                        <FileCard
                          file={file}
                          imgHeight="7rem"
                          cardWidth="14rem"
                          showPicture={true}
                          canDownload={false}
                          readOnly={true}
                        />
                      </SClickDiv>
                    </Col>
                  ))}
                </Row>
              </>
            )
          )}
        </SlistDiv>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          className="mx-2"
          onClick={() => {
            props.onOK(selectedPictures);
          }}
          title="決定"
        />
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SlistDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;
  margin: 0;
`;

const SClickDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: stretch;
  &.selected {
    box-shadow: 0px 0px 8px orange;

    border-radius: 5px;
  }
`;
