import { memo, useEffect, useState } from "react";
import { Badge, Col, FloatingLabel, Form, Modal, Row, Stack } from "react-bootstrap";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { STEP_YEN, DAY_TYPE } from "common/types/consts/Defines";
import { NameID } from "common/types/NameID";
import { getHolidayName } from "utils/commonTools";

import { PayFacilityUsage } from "../types/PayFacilityData";
import { PayFacilityMeta } from "../types/PayFacilityMeta";
import { CancelButton } from "common/components/atoms/CancelButton";

export type TargetFacility = {
  index: number; // 0以下なら新規
  data: PayFacilityUsage;
  metaData: PayFacilityMeta;
  paymentMethods?: NameID[];
  currentDate: Date;
};

type Props = {
  target: TargetFacility | null;
  onOK: (data: PayFacilityUsage) => void;
  onCancel: () => void;
};

const CLEAR: string = "clear";

//=====================================
// 有料施設編集ダイアログ
//=====================================
export const EditPayFacilityDlg = memo(({ target, onOK, onCancel }: Props) => {
  const [item, setItem] = useState<PayFacilityUsage | null>(null);
  const holidayName = target && getHolidayName(target.currentDate);
  //--------------------------------------
  // 料金の計算
  // 対象となる設定の組み合わせなら自動計算する
  //--------------------------------------
  const calcFee = (item: PayFacilityUsage) => {
    const dayId = holidayName ? DAY_TYPE.HOLIDAY : DAY_TYPE.NORMAL;
    var calcInfo = target!.metaData.fees.find(
      (x) =>
        x.usageID === (item.usageType?.id ?? null) &&
        x.periodID === (item.periodType?.id ?? null) &&
        x.exemptionID === (item.exemptionType?.id ?? null) &&
        x.dayID === (dayId ?? null)
    );
    if (!calcInfo) return item.fee;

    return calcInfo.fee * item.count;
  };

  useEffect(() => {
    setItem(target?.data ?? null);
  }, [target]);

  return (
    <Modal show={target !== null} size="lg">
      <Modal.Header>
        {target &&
          `${target.metaData.facility.name}利用情報の${target.index < 0 ? "追加" : "編集"}`}
      </Modal.Header>
      <Modal.Body>
        {item && target && (
          <Stack className="gap-2">
            {target.metaData.usageTypes && (
              <Form.Group as={Row}>
                <Form.Label column md={3}>
                  利用区分
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    aria-label="利用区分"
                    value={`${item.usageType?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, usageType: null });
                        return;
                      }

                      // 選択
                      const cat = target.metaData.usageTypes.find((x) => x.id.toString() === val);
                      item.usageType = cat ?? null;
                      setItem({ ...item, fee: calcFee(item) });
                    }}
                  >
                    <option value={CLEAR}>利用区分を選択</option>
                    {target.metaData.usageTypes.map((cat) => {
                      return (
                        <option key={`usage_${cat.id}`} value={cat.id}>
                          {cat.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            {target.metaData.periodTypes.length > 0 && (
              <Form.Group as={Row}>
                <Form.Label column md={3}>
                  時間区分
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    aria-label="時間区分"
                    value={`${item.periodType?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, periodType: null });
                        return;
                      }

                      // 選択
                      const cat = target.metaData.periodTypes.find((x) => x.id.toString() === val);
                      item.periodType = cat ?? null;
                      setItem({ ...item, fee: calcFee(item) });
                    }}
                  >
                    <option value={CLEAR}>時間区分を選択</option>
                    {target.metaData.periodTypes.map((cat) => {
                      return (
                        <option key={`period_${cat.id}`} value={cat.id}>
                          {cat.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            {target.metaData.exemptionTypes.length > 0 && (
              <Form.Group as={Row}>
                <Form.Label column md={3}>
                  減免区分
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    aria-label="減免区分"
                    value={`${item.exemptionType?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, exemptionType: null });
                        return;
                      }

                      // 選択
                      const cat = target.metaData.exemptionTypes.find(
                        (x) => x.id.toString() === val
                      );
                      item.exemptionType = cat ?? null;
                      setItem({ ...item, fee: calcFee(item) });
                    }}
                  >
                    <option value={CLEAR}>なし</option>
                    {target.metaData.exemptionTypes.map((cat) => {
                      return (
                        <option key={`exemption_${cat.id}`} value={cat.id}>
                          {cat.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            {target.metaData.fees.length > 0 && target.paymentMethods && (
              <Form.Group as={Row}>
                <Form.Label column md={3}>
                  支払方法
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    aria-label="支払方法"
                    value={`${item.paymentType?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, paymentType: null });
                        return;
                      }

                      // 選択
                      const cat = target.paymentMethods?.find((x) => x.id.toString() === val);
                      setItem({ ...item, paymentType: cat ?? null });
                    }}
                  >
                    <option value={CLEAR}>支払方法を選択してください</option>
                    {target.paymentMethods?.map((cat) => {
                      return (
                        <option key={`paytype_${cat.id}`} value={cat.id}>
                          {cat.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                利用件数
              </Form.Label>
              <Col sm={4}>
                <MyNumberInput
                  min={-9999999}
                  initialValue={item.count}
                  changed={(value) => {
                    item.count = value;
                    setItem({ ...item, fee: calcFee(item) });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                利用人数
              </Form.Label>
              <Col sm={4}>
                <MyNumberInput
                  min={-9999999}
                  initialValue={item.numUser}
                  changed={(value) => {
                    setItem({ ...item, numUser: value });
                  }}
                />
              </Col>
            </Form.Group>
            {target.metaData.fees.length > 0 && (
              <Form.Group as={Row}>
                <Form.Label column md={3}>
                  利用料金
                </Form.Label>
                <Col sm={4}>
                  <MyNumberInput
                    min={-9999990}
                    step={STEP_YEN}
                    initialValue={item.fee}
                    changed={(value) => {
                      setItem({ ...item, fee: value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  {holidayName && (
                    <Badge pill bg="danger">
                      土日祝
                    </Badge>
                  )}
                </Col>
              </Form.Group>
            )}
            <FloatingLabel controlId="floatingInput" label="備考">
              <Form.Control
                as="textarea"
                value={item?.note}
                onChange={(e) => {
                  setItem({ ...item, note: e.target.value });
                }}
                style={{ height: "100px" }}
              />
            </FloatingLabel>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        {item && <PrimaryButton className="mx-2" onClick={() => onOK(item!)} title="決定" />}
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
});
