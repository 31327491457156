import styled from "styled-components";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { NameID } from "common/types/NameID";
import { useEffect, useState } from "react";
import { Form, ListGroup, Modal } from "react-bootstrap";
import { CancelButton } from "../atoms/CancelButton";

type Props = {
  show: boolean;
  lists: NameID[];
  selecteds: NameID[];
  onOK: (vals: NameID[]) => void;
  onCancel: () => void;
};

export const SelectNameIDDlg = (props: Props) => {
  const { show, onOK, onCancel } = props;
  const [selecteds, setSelecteds] = useState<NameID[]>([]);

  useEffect(() => {
    setSelecteds([...props.selecteds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <SModal show={show} variant="info" centered>
      <Modal.Body>
        <ListGroup as="ul" style={{ overflow: "auto" }}>
          {props.lists.map((val) => (
            <ListGroup.Item key={`val_${val.id}`}>
              <Form.Check
                label={val.name}
                checked={selecteds.find((x) => x.id === val.id) !== undefined}
                onChange={(e) => {
                  let newVals = e.target.checked
                    ? [...selecteds, val]
                    : selecteds.filter((x) => x.id !== val.id);
                  setSelecteds(newVals);
                }}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          className="mx-2"
          onClick={() => {
            onOK(selecteds);
          }}
          title="OK"
        />
        <CancelButton onClick={onCancel} />
      </Modal.Footer>
    </SModal>
  );
};

const SModal = styled(Modal)`
  filter: drop-shadow(5px 5px 5px #c3c3c3);
`;
