import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import styled from "styled-components";

import MyTextArea from "common/components/atoms/MyTextArae";
import MyTextbox from "common/components/atoms/MyTextbox";
import { FileListCards } from "common/components/organisms/files/FileListCards";
import { RevenueReportPanel } from "pages/parkevents/components/RevenueReportPanel";
import { EventParticipantsTable } from "pages/parkevents/components/EventParticipantsTable";
import { FILE_ACCEPTS_IMAGES } from "common/types/consts/Defines";
import FunctionButton from "common/components/atoms/FunctionButton";
import { toFormattedDate } from "utils/DateTools";
import { PeriodicalEventRecord } from "../types/PeriodicalEvent";
import { useEffect, useState } from "react";
import { ParkEventBase } from "pages/parkevents/types/ParkEventData";
import { SelectPictureDlg } from "./SelectPictureDlg";
import { EVENT_TYPE } from "pages/parkevents/types/EVENT_TYPE";

type Params = {
  from?: Date;
  to?: Date;
  record: PeriodicalEventRecord;
  eventType: EVENT_TYPE;
  readonly: boolean;
  printRequested: () => Promise<void>;
};

export const PeriodEventSummary = (params: Params) => {
  const { from, to, record, eventType, readonly, printRequested } = params;
  const [data, setData] = useState<ParkEventBase>();

  const [showSelPictures, setShowSelPictures] = useState(false);

  useEffect(() => {
    setData(record.summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (!data) return <span>集計データがセットされていません</span>;

  return (
    <>
      <Stack className="p-2 py-3" gap={1}>
        <Container className="p-0 d-flex justify-content-between align-items-center">
          <SPeriodDiv>
            <SLabel>期間 </SLabel>
            <MyTextbox
              style={{ width: "auto" }}
              initialValue={from ? toFormattedDate(from) : "なし"}
              readonly={true}
              changed={(_val) => {
                return;
              }}
              borderAlways={true}
            />
            <SLabel>～</SLabel>
            <MyTextbox
              style={{ width: "auto" }}
              initialValue={to ? toFormattedDate(to) : "なし"}
              readonly={true}
              changed={(_val) => {
                return;
              }}
              borderAlways={true}
            />
          </SPeriodDiv>
          {/* 印刷 */}
          <FunctionButton
            size="md"
            data-tip="印刷"
            tooltip="印刷"
            onClick={(e) => {
              e.preventDefault();
              printRequested();
            }}
          >
            <i className="fas fa-print" />
          </FunctionButton>
        </Container>

        <SLabel htmlFor="txtDescription">概要</SLabel>
        <MyTextArea
          id="txtDescription"
          readOnly={readonly}
          value={data.description}
          onChange={(value) => (data.description = value)}
        />

        <SLabel htmlFor="txtReport">報告内容</SLabel>
        <MyTextArea
          id="txtReport"
          readOnly={readonly}
          value={data.report}
          onChange={(value) => (data.report = value)}
        />
        <Row>
          <Col sm={12} lg={5}>
            <SLabel>参加人数 (自動集計）</SLabel>
            <div style={{ width: 300 }}>
              {data.participants.length > 0 ? (
                <EventParticipantsTable participants={data.participants} readonly={true} />
              ) : (
                <div className="pt-1 pb-3">人数種別の定義がありません</div>
              )}
            </div>
          </Col>
          <Col sm={12} lg={5}>
            {eventType !== EVENT_TYPE.Collaborate && data.revenueReport && (
              <>
                <SLabel>収支報告（自動集計）</SLabel>
                <RevenueReportPanel revenue={data.revenueReport} readonly={true} />
              </>
            )}
          </Col>
        </Row>
        <SLabel htmlFor="txtNote">特記事項</SLabel>
        <MyTextArea
          className="form-control"
          id="txtNote"
          readOnly={readonly}
          value={data.note}
          onChange={(value) => (data.note = value)}
        />
        <SPictEditDiv>
          {!readonly && (
            <SFunctionButton
              onClick={() => setShowSelPictures(true)}
              size="md"
              tooltip="写真を選択する"
            >
              {<i className="far fa-images" />}
            </SFunctionButton>
          )}
        </SPictEditDiv>
        <FileListCards
          files={data.pictures}
          attr={{
            title: "写真",
            accepts: FILE_ACCEPTS_IMAGES,
            usePicture: true,
            hasComment: true,
            imgHeight: "8rem",
          }}
          readonly={true}
          listChanged={(_files) => {}}
        />
      </Stack>

      {showSelPictures && (
        <SelectPictureDlg
          show={showSelPictures}
          candidates={record.details.map((x) => {
            return {
              title: toFormattedDate(x.date),
              pictures: x.pictures.filter((y) => y.fileId >= 0),
            };
          })}
          selecteds={data.pictures}
          onOK={(vals) => {
            data.pictures = vals;
            setShowSelPictures(false);
          }}
          onCancel={() => setShowSelPictures(false)}
        />
      )}
    </>
  );
};

const SPeriodDiv = styled.div`
  font-size: medium;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1rem;
`;

const SLabel = styled(Form.Label)`
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
`;

const SPictEditDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const SFunctionButton = styled(FunctionButton)`
  position: absolute;
  margin-top: 5px;
`;
