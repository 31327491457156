import { useContext } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import AuthContext from "common/store/AuthContext";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

import LoginPage from "pages/user/LoginPage";
import SelectParkPage from "pages/user/SelectParkPage";
import ChangePasswordPage from "pages/user/ChangePasswordPage";
import HomePage from "pages/home/HomePage";
import ParkStaffsPage from "pages/parkstaffs/ParkStaffsPage";
import ParkVisitorsPage from "pages/visitors/ParkVisitorsPage";
import InquiryPage from "pages/inquiry/InquiryPage";
import ExclusiveUsesPage from "pages/exclusiveuses/ExclusiveUsesPage";
import SpecialNotesPage from "pages/specialnotes/SpecialNotesPage";
import PayFacilityPage from "pages/payfacility/PayFacilityPage";
import { WorkReportPage } from "pages/workreport/WorkReportPage";
import { IndependentEventPage } from "pages/parkevents/IndependentEventPage";
import { CollaborateEventPage } from "pages/parkevents/CollaborateEventPage";
import IncidentPage from "pages/incidents/IncidentPage";
import { PrintTemplatePage } from "pages/print/PrintTemplatePage";
import { PrintPage } from "pages/print/PrintPage";
import CommentsPage from "pages/comments/CommentsPage";
import { PeriodEventPage } from "pages/periodEvent/PeriodEventPage";
import { StaffMaintenancePage } from "pages/maintenance/StaffMaintenancePage";

function App() {
  const { selectedPark, isLoggedIn } = useContext(AuthContext);

  const getPageTitle = (tabID: number, reportType: REPORT_TYPE) => {
    if (reportType === REPORT_TYPE.WORK)
      return selectedPark?.workTabs?.find((x) => x.id === tabID)?.name ?? "タブ名不明";

    return selectedPark?.dailyTabs?.find((x) => x.id === tabID)?.name ?? "タブ名不明";
  };

  const router = !isLoggedIn
    ? createBrowserRouter([{ path: "*", element: <LoginPage /> }])
    : !selectedPark
    ? createBrowserRouter([{ path: "*", element: <SelectParkPage /> }])
    : createBrowserRouter([
        {
          path: "/password",
          element: <ChangePasswordPage />,
        },
        {
          path: "/",
          element: <HomePage pageTitle="ホーム" />,
        },
        {
          path: "/print",
          element: <PrintPage pageTitle="出力" />,
        },
        {
          path: "/parkstaffs/daily",
          element: (
            <ParkStaffsPage
              pageTitle={getPageTitle(TAB_ID.STAFFS, REPORT_TYPE.DAILY)}
              reportType={REPORT_TYPE.DAILY}
            />
          ),
        },
        {
          path: "/parkstaffs/work",
          element: (
            <ParkStaffsPage
              pageTitle={getPageTitle(TAB_ID.STAFFS, REPORT_TYPE.WORK)}
              reportType={REPORT_TYPE.WORK}
            />
          ),
        },
        {
          path: "/visitors",
          element: (
            <ParkVisitorsPage pageTitle={getPageTitle(TAB_ID.VISITORS, REPORT_TYPE.DAILY)} />
          ),
        },
        {
          path: "/inquiry",
          element: <InquiryPage pageTitle={getPageTitle(TAB_ID.INQUIRY, REPORT_TYPE.DAILY)} />,
        },
        {
          path: "/incidents",
          element: <IncidentPage pageTitle={getPageTitle(TAB_ID.INCIDENTS, REPORT_TYPE.DAILY)} />,
        },
        {
          path: "/exclusiveuses",
          element: (
            <ExclusiveUsesPage pageTitle={getPageTitle(TAB_ID.EXCLUSIVE_USE, REPORT_TYPE.DAILY)} />
          ),
        },
        {
          path: "/payfacility",
          element: (
            <PayFacilityPage pageTitle={getPageTitle(TAB_ID.PAY_FACILITY, REPORT_TYPE.DAILY)} />
          ),
        },
        {
          path: "/parkEvents",
          children: [
            {
              path: "independent/:id?",
              element: (
                <IndependentEventPage
                  pageTitle={getPageTitle(TAB_ID.PRIVATE_BIZ, REPORT_TYPE.DAILY)}
                />
              ),
            },
            {
              path: "collaborate/:id?",
              element: (
                <CollaborateEventPage
                  pageTitle={getPageTitle(TAB_ID.PUBLIC_BIZ, REPORT_TYPE.DAILY)}
                />
              ),
            },
            {
              path: "periodical",
              element: (
                <PeriodEventPage
                  pageTitle={getPageTitle(TAB_ID.PERIODICAL_EVENT, REPORT_TYPE.DAILY)}
                />
              ),
            },
          ],
        },
        {
          path: "/specialnotes",
          children: [
            {
              path: "daily",
              element: (
                <SpecialNotesPage
                  pageTitle={getPageTitle(TAB_ID.SPECIAL_NOTE, REPORT_TYPE.DAILY)}
                  reportType={REPORT_TYPE.DAILY}
                />
              ),
            },
            {
              path: "work",
              element: (
                <SpecialNotesPage
                  pageTitle={getPageTitle(TAB_ID.SPECIAL_NOTE, REPORT_TYPE.WORK)}
                  reportType={REPORT_TYPE.WORK}
                />
              ),
            },
          ],
        },
        {
          path: "/comments",
          children: [
            {
              path: "daily",
              element: (
                <CommentsPage
                  pageTitle={getPageTitle(TAB_ID.COMMENT, REPORT_TYPE.DAILY)}
                  reportType={REPORT_TYPE.DAILY}
                />
              ),
            },
            {
              path: "work",
              element: (
                <CommentsPage
                  pageTitle={getPageTitle(TAB_ID.COMMENT, REPORT_TYPE.WORK)}
                  reportType={REPORT_TYPE.WORK}
                />
              ),
            },
          ],
        },
        {
          path: "/workreport",
          element: (
            <WorkReportPage pageTitle={getPageTitle(TAB_ID.WORK_REPORT, REPORT_TYPE.WORK)} />
          ),
        },
        {
          path: "/settings",
          children: [
            {
              path: "printtemplate",
              element: <PrintTemplatePage pageTitle="帳票登録" />,
            },
            {
              path: "staffs",
              element: <StaffMaintenancePage pageTitle="勤務者設定" />,
            },
          ],
        },
      ]);

  return <RouterProvider router={router} />;
}

export default App;
